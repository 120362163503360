import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  select
} from "redux-saga/effects";

import axios from "axios";
import {
    LOAD_COUNTS_FOR_SIDENAV_REQUEST,
    LOAD_COUNTS_FOR_SIDENAV_SUCCESS,
    LOAD_COUNTS_FOR_SIDENAV_FAILURE,

    LOAD_SUMMARY_REQUEST,
    LOAD_SUMMARY_SUCCESS,
    LOAD_SUMMARY_FAILURE,

    LOAD_STOCK_REQUEST,
    LOAD_STOCK_SUCCESS,
    LOAD_STOCK_FAILURE,
} from '../reducers/analytics';

const getUser = state => {
    return state.user.me;
}

function loadCountsForSidenavAPI() {
  // 서버에 요청을 보내는 부분
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  return axios.get("/analytics/countsForSideNav", {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Brand: brand
    }
  });
}

function loadSummaryAPI(user, query) {
  // 서버에 요청을 보내는 부분
  let token = "";
  if(user && user.token) {
      token = `bearer${user.token}`;
  } else {
      token = `bearer${localStorage.getItem("token")}`;
  }
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `analytics/summary${query}`
  } else {
      endPoint = "analytics/summary";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Authorization : token,
        Brand: brand
    }
  });
}

function loadStockAPI(query) {
  // 서버에 요청을 보내는 부분
  const brand = localStorage.getItem('selectedBrand') ? localStorage.getItem('selectedBrand') : "";
  let endPoint = "";
  if(query) {
      endPoint = `analytics/stock${query}`
  } else {
      endPoint = "analytics/stock";
  }
  return axios.get(endPoint, {
    withCredentials: true, // 클라이언트에서 요청 보낼 때는 브라우저가 쿠키를 같이 동봉
    headers: {
        Brand: brand
    }
  });
}

function* loadCountsForSidenav(action) {
  try {
    const result = yield call(loadCountsForSidenavAPI);
    yield put({
      // put은 dispatch 동일
      type: LOAD_COUNTS_FOR_SIDENAV_SUCCESS,
      data: result.data.countsForSideNav,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_COUNTS_FOR_SIDENAV_FAILURE,
      error: e.response.data,
    });
  }
}

function* loadSummary(action) {
  try {
      const user =  yield select(getUser);
      const result = yield call(loadSummaryAPI, user, action.data);
      yield put({
          // put은 dispatch 동일
          type: LOAD_SUMMARY_SUCCESS,
          data: result.data,
      });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_SUMMARY_FAILURE,
      error: e.response.data,
    });
  }
}

function* loadStock(action) {
  try {
    const result = yield call(loadStockAPI, action.data);
    yield put({
      // put은 dispatch 동일
      type: LOAD_STOCK_SUCCESS,
      data: result.data,
    });
  } catch (e) {
    // loginAPI 실패
    yield put({
      type: LOAD_STOCK_FAILURE,
      error: e.response.data,
    });
  }
}

function* watchLoadCountsForSidenav() {
  yield takeEvery(LOAD_COUNTS_FOR_SIDENAV_REQUEST, loadCountsForSidenav);
}

function* watchLoadSummary() {
  yield takeEvery(LOAD_SUMMARY_REQUEST, loadSummary);
}

function* watchLoadStock() {
  yield takeEvery(LOAD_STOCK_REQUEST, loadStock);
}

export default function* userSaga() {
  yield all([fork(watchLoadCountsForSidenav), fork(watchLoadSummary), fork(watchLoadStock)]);
}
