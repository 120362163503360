import React from 'react';
import { DateRange } from 'react-date-range';

const DateRangePicker = ({target1, target2, data, onChange}) => {
    const formatDate = (date) => {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) {
            month = `0${month}`;
        }
        if (day.length < 2) {
            day = `0${day}`;
        }

        return [year, month, day].join('-');
    };

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date()
    };

    const handleDateRange = (value) => {
        selectionRange.startDate = value.range1.startDate;
        selectionRange.endDate = value.range1.endDate;

        let data = {};
        data[target1] = formatDate(selectionRange.startDate);
        data[target2] = formatDate(selectionRange.endDate);
        onChange(data);
    };

    return (
        <div uk-dropdown="mode:click; boundary:#boundary1; boundary-align:true; pos:bottom-center;" className="uk-padding-small uk-padding-remove-top uk-padding-remove-horizontal">
            <DateRange ranges={[selectionRange]} moveRangeOnFirstSelection={false} dateDisplayFormat="YYYY-MM-DD" onChange={handleDateRange} />
            <div className="uk-text-center">
                <button type="button" onClick={()=>{document.getElementById(target1).click();}} className="uk-button uk-button-default uk-button-small">데이트레인지</button>
            </div>
        </div>
    );
}

export default DateRangePicker;
