import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadMeAction } from '../../reducers/user';
import { loadCountsForSidenavAction } from '../../reducers/analytics';

import Header from "../../Components/Widget/Header";
import UIKit from "../../Components/UIKit";
import ConfigData from "../../ConfigData";
import Module from '../../Components/Module';

import {getAgoDate} from '../../common.js';

const Summary = () => {
    const dispatch = useDispatch();
    const { loadMeLoading, loadMeDone } = useSelector((state) => state.user);

    const configData = {
        analytics : { object: new ConfigData.Analytics(), query: useState({date:btoa(JSON.stringify(getAgoDate(6)))}) },
        analytics2 : { object: new ConfigData.Analytics(), query: useState({date:btoa(JSON.stringify(getAgoDate(3)))}) }
    };

    useEffect(() => {
        dispatch(loadMeAction());
        dispatch(loadCountsForSidenavAction());
    }, []);

    const test = () => {
        configData.analytics.query[1]({date:btoa(JSON.stringify(getAgoDate(3)))});
    }

    return loadMeLoading ? null : loadMeDone && (
        <div>
            <div className="uk-width-1-1">
                <Header title={"개요"} />
            </div>
            <div className="uk-width-1-1">
                <div className="uk-grid uk-grid-small" data-uk-grid data-uk-height-match="target: > div > .uk-card">
                    <div className="uk-width-1-1">
                        <a href="#">
                            <input
                                className="uk-input uk-form-small"
                                id="startDate"
                                name="startDate"
                                type="text"
                                readOnly
                                placeholder={"시작 날짜"}
                            />
                        </a>
                        <UIKit.DatePicker target={"startDate"} onChange={test} />
                    </div>
                    <div className="uk-width-1-1 uk-width-1-2@m">
                        <div className="uk-card uk-card-default uk-card-small">
                            <div className="uk-card-header uk-text-0_9">
                                <p>방문자 현황</p>
                            </div>
                            <div className="uk-card-body uk-overflow-auto uk-height-medium uk-height-large@m uk-flex@m uk-flex-middle uk-position-relative">
                                <Module.Chart.Type1 instance={configData.analytics.object.get("list", configData.analytics.query[0])} isDispatch={true} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-1 uk-width-1-2@m">
                        <div className="uk-card uk-card-default uk-card-small">
                            <div className="uk-card-header uk-text-0_9">
                                <p>일자별 요약</p>
                            </div>
                            <div className="uk-card-body uk-padding-xsmall-horizontal uk-padding-horizontal@m uk-position-relative uk-overflow-auto">
                                <Module.List.Type2 instance={configData.analytics2.object.get("list", configData.analytics2.query[0])} isDispatch={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Summary;
